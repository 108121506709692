import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { ToasterService } from "src/app/@AppService/services/toaster.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private toasterService: ToasterService;

  constructor(private router: Router, private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.toasterService = this.injector.get(ToasterService);

        switch (err.status) {
        case 401:
          localStorage.removeItem("token");
          this.toasterService.showErrorToaster("ERRORS.UNAUTHORIZED");
          this.router.navigate(["login"]);
          break;
        case 400:
          if (err.error.message.length !== 0) {
            this.toasterService.showErrorToaster(err.error.message[0]);
          } else {
            this.toasterService.showErrorToaster();
          }
          break;
        case 403:
          this.toasterService.showErrorToaster("ERRORS.FORBIDDEN");
          // THIS IS JUST BECAUSE CUSTOMER IDS ARE CHANGED IN THE BACKEND SO WE NEED TO REFRESH IT
          this.router.navigate(["login/account"]);
          break;
        case 404:
          this.router.navigate(["not-found"]);
          break;
        case 500:
          this.toasterService.showErrorToaster("ERRORS.SERVER_ERROR");
          break;
        default:
          break;
        }

        return throwError(() => err);
      })
    );
  }
}
