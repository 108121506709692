import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() public clickOutside = new EventEmitter<boolean>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener("document:mousedown", ["$event"])
  public onGlobalClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    const isTargetInsideElement = this._elementRef.nativeElement.contains(targetElement);

    if (!isTargetInsideElement) {
      this.clickOutside.emit(true);
    }
  }

}
