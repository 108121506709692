<div class="dropdown col text-end">
  <button
    class="btn btn-white dropdown-toggle dropdown__dropdownbttn"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <div class="d-flex justify-content-between align-content-center">
      <div class="p-0">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 9.75C16.5 9.33579 16.8358 9 17.25 9H18.75C19.1642 9 19.5 9.33579 19.5 9.75V11.25C19.5 11.6642 19.1642 12 18.75 12H17.25C16.8358 12 16.5 11.6642 16.5 11.25V9.75Z"
            fill="#01513A"
          />
          <path
            d="M12 9.75C12 9.33579 12.3358 9 12.75 9H14.25C14.6642 9 15 9.33579 15 9.75V11.25C15 11.6642 14.6642 12 14.25 12H12.75C12.3358 12 12 11.6642 12 11.25V9.75Z"
            fill="#01513A"
          />
          <path
            d="M4.5 14.25C4.5 13.8358 4.83579 13.5 5.25 13.5H6.75C7.16421 13.5 7.5 13.8358 7.5 14.25V15.75C7.5 16.1642 7.16421 16.5 6.75 16.5H5.25C4.83579 16.5 4.5 16.1642 4.5 15.75V14.25Z"
            fill="#01513A"
          />
          <path
            d="M9 14.25C9 13.8358 9.33579 13.5 9.75 13.5H11.25C11.6642 13.5 12 13.8358 12 14.25V15.75C12 16.1642 11.6642 16.5 11.25 16.5H9.75C9.33579 16.5 9 16.1642 9 15.75V14.25Z"
            fill="#01513A"
          />
          <path
            d="M5.25 0C5.66421 0 6 0.335786 6 0.75V1.5H18V0.75C18 0.335786 18.3358 0 18.75 0C19.1642 0 19.5 0.335786 19.5 0.75V1.5H21C22.6569 1.5 24 2.84315 24 4.5V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V4.5C0 2.84315 1.34315 1.5 3 1.5H4.5V0.75C4.5 0.335786 4.83579 0 5.25 0ZM1.5 6V21C1.5 21.8284 2.17157 22.5 3 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V6H1.5Z"
            fill="#01513A"
          />
        </svg>
      </div>

      <div class="p-0 dropdown__date">
        <p class="mb-0">
          {{ datePickerService.getSelectedYear() }}
        </p>
      </div>

      <div class="d-flex justify-items-center align-items-center">
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </button>
  <ul class="dropdown-menu dropdown__menu">
    <li
      class="pointer w-100"
      (click)="openConfirmation(year)"
      *ngFor="let year of yearList"
    >
      <a class="dropdown-item dropdown__item">{{ year }}</a>
    </li>
  </ul>
</div>

<!-- confirm change year model -->
<app-modal
  [title]="'GLOBAL_YEAR.TITLE' | translate"
  *ngIf="openConfirmModel"
  (modalClosed)="openConfirmModel = false; formService.submitted = false"
  [closeBtnTitle]="'CANCEL' | translate"
  [submitBtnTitle]="'GLOBAL_YEAR.CONFIRM' | translate"
  (modalSubmitted)="changeYear(yearToConfirmChange)"
  [wrapperClasses]="'w-25'"
>
  <div>
    <h3 class="text-black fw-normal text-break">
      {{ "GLOBAL_YEAR.CONFIRM_MSG" | translate }}
    </h3>
  </div>
</app-modal>
