import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { OnDestroy } from "@angular/core";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from "@angular/core";
import { Observable, Subscription, timer } from "rxjs";
import { EventType } from "src/app/@AppService/enums/event-types";
import { ToasterService } from "src/app/@AppService/services/toaster.service";

@Component({
  selector: "app-toaster",
  templateUrl: "./toaster.component.html",
  styleUrls: ["./toaster.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("hiddenVisible", [
      state(
        "visible",
        style({
          opacity: 1,
          " z-index": 5
        })
      ),
      state(
        "hidden",
        style({
          opacity: 0,
          "z-index": -5
        })
      ),
      transition("visible => hidden", [animate("0.3s")]),
      transition("hidden => visible", [animate("0.5s")])
    ])
  ]
})
export class ToasterComponent implements OnInit, OnDestroy {
  visibility = false;
  disposable!: Subscription;
  subTimeout: Observable<0>;
  timeoutDisposable!: Subscription;
  constructor(
		private toastService: ToasterService,
		private cdr: ChangeDetectorRef
  ) {}

  message!: string;
  style!: any;

  ngOnInit() {
    this.disposable = this.toastService.toastObservable.subscribe(toast => {
      this.message = toast.message;
      this.subTimeout = timer(toast.timeout);
      this.style = this.getStyle(toast.type);
      if (toast.message.length > 0) {
        this.show();
      }
    });
  }

  setToasterTimeout() {
    this.timeoutDisposable = this.subTimeout.subscribe(t => {
      this.hide();
    });
  }

  show() {
    this.timeoutDisposable?.unsubscribe();
    this.visibility = true;
    this.cdr.detectChanges();
    this.setToasterTimeout();
  }

  hide() {
    this.visibility = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.disposable?.unsubscribe();
  }

  getStyle(eventType: EventType): string {
    switch (eventType) {
    case EventType.SUCCESS:
      return "bg-toast-success text-toast-t-success";
    case EventType.ERROR:
      return "bg-toast-error text-toast-t-error";
    case EventType.INFO:
      return "bg-toast-info text-toast-t-info";
    case EventType.WARNING:
      return "bg-toast-warning text-toast-t-warning";
    default:
      return `bg-dark text-white`;
    }
  }
}
