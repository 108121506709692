import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { tokenInterceptor } from "./@Core/interceptors/authToken.interceptor";

const baseTranslateUrl = "assets/i18n/";
const baseTranslateExtension = ".json";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    baseTranslateUrl,
    baseTranslateExtension
  );
}
import { registerLocaleData } from "@angular/common";
import localDa from "@angular/common/locales/da";
import { ErrorInterceptor } from "./@Core/interceptors/error-interceptor.interceptor";
import { LanguageInterceptor } from "./@Core/interceptors/language-interceptor.interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: tokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localDa);
  }
}
