import { Component, Injector, Input, OnInit, Signal, booleanAttribute, effect, inject, numberAttribute, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { IDropdownActions } from "src/app/@AppService/models/dropdown-actions-interface";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"]
})
export class DropdownComponent implements OnInit{
  @Input({ required: true }) actions: IDropdownActions[] = [];
  @Input() labelLocalizationKey = "";
  @Input() labelStyles = "";
  @Input({ transform: booleanAttribute }) displayChosenValue = false;
  @Input() componentStyles = "";
  @Input() actionsStyles = "";
  @Input({transform: numberAttribute}) width = 142;
  @Input({transform: booleanAttribute}) isDisabled = false;
  @Input() embeddedFormControl?: FormControl<string> | null;
  
  private injector = inject(Injector);
  
  menuShown = false;
  displayValue: Signal<string>;

  ngOnInit(): void {
    if(this.embeddedFormControl){
      this.displayValue = toSignal<string, string>(this.embeddedFormControl.valueChanges, 
        { initialValue: this.embeddedFormControl.value, injector: this.injector })
    }else{
      this.displayValue = signal(this.labelLocalizationKey);
    }
    
    effect(() => {
      this.displayValue()
    }, { injector: this.injector })

  }

  actionIdentifier(index: number) {
    return index;
  }

  handleClick(index: number) {
    if(index > this.actions.length - 1){
      return
    }
    this.actions[index].callback(index);
    if(this.displayChosenValue){
      this.displayValue = signal(this.actions[index].localizationKey)
    }
  }
}
