import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundGlobalComponent } from "./modules/error-pages/not-found-global/not-found-global.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule)
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/modules.module").then(m => m.ModulesModule)
  },
  {
    path: "**",
    loadComponent: () => NotFoundGlobalComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" , bindToComponentInputs: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
