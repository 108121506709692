import { inject, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../@AppService/services/language.service';

@Pipe({
  name: 'numberToLocale'
})
export class NumberToLocalePipe implements PipeTransform {
  languageService = inject(LanguageService);
  transform(value: number): string {
    if(Number.isNaN(value)){
      return "0";
    }
    const lang = this.languageService.getCurrentLanguage();

    switch(lang){
    case 'en':
      return value.toLocaleString('en-US');
    default:
      return value.toLocaleString('da-DK');
    }
  }

}
