import { Component, Input, numberAttribute } from "@angular/core";
import { IProgressBarSettings } from "../../@AppService/models/progress-bar-settings.interface";

@Component({
  selector: "app-progress",
  templateUrl: "./progress.component.html",
  styleUrls: ["./progress.component.scss"]
})
export class ProgressComponent {
  
  @Input({ required: true, transform: numberAttribute })
    progressPercent: number;
  @Input() progressWidth = "100px";
  @Input({ required: true }) settings: IProgressBarSettings;
  protected get normalizedProgressPercent(): number {
    return this.progressPercent < 100 ? this.progressPercent : 100;
  }

  protected get progressColor(): string {
    if (this.settings?.threshold) {
      return this.progressPercent < this.settings.threshold ? this.settings.defaultColor : this.settings.afterThresholdColor ?? "bg-owned";
    }
    return this.settings.defaultColor;
  }
}
