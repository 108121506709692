<div class="progress-container">
	<div class="progress" [ngStyle]="{ width: progressWidth }">
		<div
			class="progress-bar"
			[ngClass]="progressColor"
			[style.width.%]="normalizedProgressPercent"
			[attr.data-progress]="progressPercent"
		></div>
		<div class="progress-percent">{{ progressPercent | numberToLocale }}%</div>
		<div
			*ngIf="this.settings?.threshold"
			class="progress-dashed-line"
			[style.left.%]="this.settings.threshold"
		></div>
	</div>
</div>
