<div
  class="container h-100 d-flex align-items-center justify-content-center flex-column"
>
  <div class="image-box">
    <img
      src="../../../assets/images/search.png"
      alt="searchIcon"
      title="searchIcon"
      class="w-100 h-100"
    />
  </div>

  <div class="searchKeyword-box">
    <p>
      <strong>( {{ searchKeyword }} )</strong>
      {{ "COULD_NOT_FIND" | translate }}
    </p>
  </div>
</div>
