import { Injectable, WritableSignal, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  private defaultLanguage = "da";
  private languageLocalStorageKey = "language";

  private currentLanguageSignal: WritableSignal<string> = signal<string>(localStorage.getItem("language") ?? this.defaultLanguage);

  constructor(
		private translateService: TranslateService,
  ) {
    if (!localStorage.getItem(this.languageLocalStorageKey)) {
      localStorage.setItem(this.languageLocalStorageKey, this.defaultLanguage);
      this.currentLanguageSignal.set(this.defaultLanguage);
    }
  }

  getCurrentLanguage = () => this.currentLanguageSignal();

  updateLanguage(language: string) {
    localStorage.setItem(this.languageLocalStorageKey, language);
    this.currentLanguageSignal.set(language);
    this.translateService.use(language);
  }

  onAppInitLoadLanguage() {
    const language = localStorage.getItem(this.languageLocalStorageKey) ?? this.defaultLanguage;
    if (language) {
      this.updateLanguage(language);
    }
  }
}
