<input
  type="text"
  [value]="displayValue"
  (input)="onInput($event)"
  (blur)="onBlur()"
  [attr.placeholder]="placeholder"
  [disabled]="isDisabled"
  [class]="appliedClasses"
  [style]="appliedStyles"
/>
