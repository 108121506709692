import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LanguageService} from "../../../@AppService/services/language.service";

@Component({
  selector: 'app-not-found-global',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './not-found-global.component.html',
  styleUrls: ['./not-found-global.component.scss']
})
export class NotFoundGlobalComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly translate = inject(TranslateService);
  private readonly languageService = inject(LanguageService);

  ngOnInit() {
    const lang = this.languageService.getCurrentLanguage()
    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use('da');
    }
  }

  goHome() {
    this.router.navigate(['/']);
  }
}
