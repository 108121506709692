import {Component, Input, booleanAttribute} from "@angular/core";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent{
  @Input({required: true}) textArr: string[];
  @Input({required: true, transform: booleanAttribute}) isBottom: boolean;
  @Input() styles = '';
}
