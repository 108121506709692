/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable, WritableSignal, signal } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class GlobalDatePickerService {
  private selectedYearSignal: WritableSignal<number> = signal(
    localStorage.getItem("year") !== null
      ? +localStorage.getItem("year")!
      : +new Date().getFullYear()
  );

  updateYear(selectedYear: number) {
    localStorage.setItem("year", selectedYear.toString());
    this.selectedYearSignal.set(selectedYear);
  }

  getSelectedYear() {
    return this.selectedYearSignal();
  }
}
