import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
  WritableSignal
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ITableHeadersInterface } from "../../@AppService/models/table-headers.interface";
import { FormControl } from "@angular/forms";
import { TableListActions } from "../../@AppService/models/table-list-actions";
import { IProductionFactorChanged } from "../../@AppService/models/iproduction-factor-changed";
import { ITableFooter } from "src/app/@AppService/models/table-footer.interface";
import { ITablePreHeaders } from "src/app/@AppService/models/table-pre-headers.interface";
import { ITooltipList } from "src/app/@AppService/models/tooltip-list.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true }) tableData: { [key: string | number]: any }[];
  @Input({ required: true }) headers: ITableHeadersInterface[];
  @Input() warnings: boolean[] = [];
  @Input() footerData: ITableFooter[] | null;
  @Input() totalDataFields: number;
  @Input() actions: TableListActions[];
  @Input({ transform: booleanAttribute }) showPagination = true;
  @Input({ transform: booleanAttribute }) alignRight = false;
  @Input() preHeaders: ITablePreHeaders[] | null;
  @Input() tooltipList: ITooltipList[];
  @Input() pageSizeSignal: WritableSignal<number>;
  @Input() pageNumberSignal: WritableSignal<number>;
  @Output() nextPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

  itemEditableId = "";
  productionFactorControl = new FormControl();
  @Output() productionFactorChanged: EventEmitter<IProductionFactorChanged> = new EventEmitter<IProductionFactorChanged>();
  @ViewChild("inputFactor") FactorInputElement!: ElementRef<HTMLInputElement>;
  paginationList: number[];
  numberOfPages: number;
  @Input() rowPadding = "1.2rem 1.6rem"
  protected visibleTooltipIndex = -1;
  private translateService = inject(TranslateService);

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnChanges(): void {
    this.generateList(this.totalDataFields, 10);
    if (this.showPagination) {
      this.numberOfPages = Math.ceil(
        this.totalDataFields / this.pageSizeSignal()
      );
    }
  }

  generateList(targetNumber: number, step: number) {
    const resultList = [];
    const newTarget = Math.ceil(targetNumber / step) * step;
    for (let i = step; i <= newTarget; i += step) {
      resultList.push(i);
    }
    this.paginationList = resultList.slice(0, 3);
  }

  next() {
    if (this.pageNumberSignal() >= this.numberOfPages) return;
    this.nextPage.emit();
  }

  previous() {
    if (this.pageNumberSignal() === 1) return;
    this.previousPage.emit();
  }

  onPageSizeChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const x = Number(target.value);

    this.numberOfPages = Math.ceil(
      this.totalDataFields / this.pageSizeSignal()
    );
    this.pageSizeChanged.emit(x);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowValueFromHeader(header: ITableHeadersInterface, item: any) {
    if (header.renderValue) return header.renderValue(item[header.value]);
    return item[header.value];
  }

  hasNotifications() {
    return this.warnings.some((hasNotification) => hasNotification);
  }

  patchValueChange(fieldId: string, productionFactor: number) {
    this.productionFactorChanged.emit({ fieldId, productionFactor });
    this.productionFactorControl.setValue(null);
    this.itemEditableId = "";
  }

  animateIcon(_: Event, icon: HTMLElement) {
    icon.classList.add("animate__animated", "animate__heartBeat");
    setTimeout(() => {
      icon.classList.remove("animate__animated", "animate__heartBeat");
    }, 1000);
  }

  navigateToLink(routeFragments: string[] = [], id?: string | null) {
    const route = routeFragments;
    if (id) route.push(id);
    this.router.navigate(route, {
      relativeTo: this.route,
    });
  }

  getFooterValueForHeader(header: ITableHeadersInterface): string {
    const footer = this.footerData?.find(footer => footer.headerName === header.name);
    return footer?.value || "";
  }

  getTooltipText(index: number): string[] {
    const textArr = [];
    for (const toolTipDateItem of this.tooltipList[index].data) {
      textArr.push(`${this.translateService.instant(toolTipDateItem.title)} ${toolTipDateItem.value === undefined ? "-" : toolTipDateItem.value} ${toolTipDateItem.unit}`);
    }
    return textArr;
  }
}
