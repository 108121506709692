<div
  [@hiddenVisible]="visibility ? 'visible' : 'hidden'"
  class="toaster position-fixed mt-5"
>
  <div
    class="toaster__background align-items-center d-flex justify-content-between rounded font"
    [ngClass]="style"
  >
    <p class="my-auto">{{ message }}</p>
    <button
      type="button"
      class="btn bg-transparent closable-button border-none me-2"
      (click)="hide()"
    >
      <img src="../../../assets/icons/close-icon.svg" />
    </button>
  </div>
</div>
