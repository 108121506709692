<button type="button" class="dropdown" (click)="menuShown = !menuShown" appClickOutside (clickOutside)="this.menuShown = false"
  [style]="componentStyles + 'width:' + width + 'px;'" [disabled]="isDisabled" [class.disabled]="isDisabled">
  <span class="label" [style]="labelStyles">
    {{ displayValue() | translate }}
  </span>
  <img src="assets/icons/chevron-down.svg" class="dropdown__img" />

  <div class="dropdown__list" *ngIf="menuShown" [ngClass]="{
			'animate__animated animate__fadeIn animate__faster': menuShown
		}" [style]="'width:' + width + 'px;'">
    <div *ngFor="
				let action of actions;
				let actionIndex = index;
				trackBy: actionIdentifier
			" class="dropdown__Item" (click)="handleClick(actionIndex)">
      {{ action.localizationKey | translate }}
    </div>
  </div>
</button>
