import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ToastEvent } from "src/app/@AppService/models/toast-event";
import { EventType } from "src/app/@AppService/enums/event-types";
import { CONSTANTS } from "src/app/@Core/constants/constant";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class ToasterService {
  _toastSubject = new BehaviorSubject<ToastEvent>({
    message: "",
    type: EventType.INFO,
    timeout: CONSTANTS.INITIAL_SNACKBAR_TIMEOUT
  });
  toastObservable: Observable<ToastEvent> = this._toastSubject.asObservable();

  constructor(private translateService: TranslateService) {}

  showToast(toast: ToastEvent) {
    this._toastSubject.next({
      message: toast.message,
      type: toast.type,
      timeout: toast.timeout
    });
  }
  showErrorToaster(messageTranslationKey = "SOME_ERROR_OCCURRED") {
    this.showToast({
      message: this.translateService.instant(messageTranslationKey),
      timeout: CONSTANTS.SNACKBAR_TIMEOUT,
      type: EventType.ERROR
    });
  }

  showSuccessToaster(msg = "SUCCESS") {
    this.showToast({
      message: this.translateService.instant(msg),
      timeout: CONSTANTS.SNACKBAR_TIMEOUT,
      type: EventType.SUCCESS
    });
  }

  showInfoToaster(msg = "SOME_ERROR_OCCURRED") {
    this.showToast({
      message: this.translateService.instant(msg),
      timeout: CONSTANTS.SNACKBAR_TIMEOUT,
      type: EventType.INFO
    });
  }
}
