import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  constructor(public translate: TranslateService) {
    translate.addLangs(["da", "en"]);
    translate.setDefaultLang("da");

    const browserLang: any = translate.getBrowserLang();
    translate.use(browserLang.match(/da|en/) ? browserLang : "da");
  }

  title = "eco-solutions";

  ngOnInit(): void {}
}
