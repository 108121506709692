import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"]
})
export class ModalComponent {
  @Input() title: string;
  @Input() closeBtnTitle: string;
  @Input() submitBtnTitle: string;
  @Input() wrapperClasses = "w-100";
  @Input() closeButtonStyle = "btn btn-outline-primary me-4";
  @Input() submitButtonStyle = "btn btn-primary";
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() modalSubmitted = new EventEmitter<boolean>();

  hideModal() {
    this.modalClosed.next(false);
  }

  submit() {
    this.modalSubmitted.next(true);
  }
}
