<div class="dark-overlay d-flex justify-content-center align-items-center">
  <div class="wrapper" [ngClass]="wrapperClasses">
    <div
      class="wrapper__title d-flex justify-content-between align-items-center"
    >
      <h2 class="fw-bold">{{ title }}</h2>
      <img
        (click)="hideModal()"
        class="wrapper__close-icon"
        src="assets/icons/times.svg"
        alt=""
      />
    </div>
    <hr />
    <div class="w-100 wrapper__content">
      <ng-content></ng-content>
    </div>
    <!-- buttons -->
    <div class="d-flex wrapper__buttons">
      <div class="ms-auto d-flex">
        <button
          type="button"
          (click)="hideModal()"
          *ngIf="closeBtnTitle"
          [ngClass]=" closeButtonStyle"
        >
          {{ closeBtnTitle }}
        </button>
        <button
          type="submit"
          (click)="submit()"
          *ngIf="submitBtnTitle"
          [ngClass]="submitButtonStyle"
        >
          {{ submitBtnTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
