import { animate, style, transition, trigger } from "@angular/animations";
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { TabComponent } from "./tab/tab.component";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-tabs-group",
  templateUrl: "./tabs-group.component.html",
  styleUrls: ["./tabs-group.component.scss"],
  animations: [
    trigger("fadeLeftAndRight", [
      transition(
        ":enter",
        [
          style({ opacity: 0, transform: "translateX({{enteringTabTranslationValue}}%)" }),
          animate("300ms ease-in-out", style({ opacity: 1, transform: "translateX(0%)" })),
        ],
        { params: { enteringTabTranslationValue: 0 } },
      ),
      transition(
        ":leave",
        [
          style({ opacity: 1, transform: "translateX(0%)", position: "absolute", inset: 0 }),
          animate("300ms ease-in-out", style({ opacity: 0, transform: "translateX({{leavingTabTranslationValue}}%)" })),
        ],
        { params: { leavingTabTranslationValue: 0 } },
      ),
    ]),
  ],
})
export class TabsGroupComponent {
  // When using this component, wrap each tab content inside 'app-tab'
  @ContentChildren(TabComponent) tabs?: QueryList<TabComponent>;
  @Input() appliedClasses = "";
  @Input() formGroup?: FormGroup;
  @Output() animationChanged = new EventEmitter<void>();
  @Output() selectedTabIndex = new EventEmitter<number>();
  @Output()  InvalidTabMovement = new EventEmitter<number>();
  selectedIndex = 0;
  enteringTabTranslationValue = 0;
  leavingTabTranslationValue = 0;

  protected selectTab(index: number): void {
    if(this.formGroup !==  undefined && this.formGroup.dirty) {
      this.InvalidTabMovement.emit(index);
      return
    }
    if (index === this.selectedIndex) return;
    this.setAnimationTranslationValues(index);
    this.setSelectedTabIndex(index);
    this.selectedTabIndex.emit(index);
  }

  protected tabIdentifier(index: number): number {
    return index;
  }

  private setAnimationTranslationValues(index: number): void {
    if (index < this.selectedIndex) {
      this.enteringTabTranslationValue = -50;
      this.leavingTabTranslationValue = 50;
    } else {
      this.enteringTabTranslationValue = 50;
      this.leavingTabTranslationValue = -50;
    }
  }

  private setSelectedTabIndex(index: number): void {
    setTimeout(() => {
      // Needs to be async in order for it to work after setting animation values
      this.selectedIndex = index;
    });
  }
}
