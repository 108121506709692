import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class tokenInterceptor implements HttpInterceptor {
  urls: Array<string>;
  currentLocation: string;
  constructor() {
    this.currentLocation = location.pathname;

    this.urls = ["/login"];
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");

    if (!token && !this.isValidRequestForInterceptor()) {
      return throwError(() => "No Token provided");
    }

    const clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(clonedReq);
  }
  isValidRequestForInterceptor(): boolean {
    if (this.urls.includes(this.currentLocation.replace("/", ""))) {
      return false;
    }
    return true;
  }
}
