<div class="row align-items-center background-image not-found">
  <div class="col">
    <h1>404</h1>
    <h2>{{"ERROR_PAGES.NOT_FOUND" | translate}}</h2>
    <p>{{"ERROR_PAGES.CONTENT_DOES_NOT_EXIST" | translate}}</p>
    <p>{{"ERROR_PAGES.REMOVED_OR_MISTYPED_LINK" | translate}}</p>
    <button class="btn btn-primary" (click)="goHome()">{{"ERROR_PAGES.BACK_TO_HOME" | translate}}</button>
  </div>
  <div class="col">
    <img src="../../../../assets/icons/not-found-light.svg">
  </div>
</div>
