<div class="tabs-group">
	<div
		#div
		(click)="
			selectTab(tabIndex);
			div.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center'
			})
		"
		*ngFor="let tab of tabs; let tabIndex = index; trackBy: tabIdentifier"
		[ngClass]="{ 'tab--active': selectedIndex === tabIndex }"
		class="tab"
	>
		<div class="tab-label-div">
			{{ tab.label | translate }}
		</div>
	</div>
</div>
<div [ngClass]="appliedClasses" class="tab-content">
	<ng-container
		*ngFor="let tab of tabs; let tabIndex = index; trackBy: tabIdentifier"
	>
		<div
			(@fadeLeftAndRight.start)="animationChanged.emit()"
			*ngIf="tabIndex === selectedIndex"
			[@fadeLeftAndRight]="{
				value: '',
				params: {
					enteringTabTranslationValue,
					leavingTabTranslationValue
				}
			}"
		>
			<ng-container *ngTemplateOutlet="tab.template"></ng-container>
		</div>
	</ng-container>
</div>
