import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root"
})
export class FormService {
  submitted = false;

  formControlHasErrors(control: AbstractControl | null) {
    return (
      (control && control.dirty && control.invalid) ||
			(control && control.touched) ||
			this.submitted
    );
  }
}
