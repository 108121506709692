import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  numberAttribute
} from "@angular/core";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent {
  @Input({transform: numberAttribute}) width = 260;
  @ViewChild("searchInputValue") searchInput: ElementRef;
  @Output() searchInputChanged: EventEmitter<string> = new EventEmitter();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
    this.catchSearch();
  }
  createForm() {
    this.form = this.fb.group({
      search: [""]
    });
  }
  catchSearch() {
    this.form
      .get("search")
      ?.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(search => {
        this.searchInputChanged.emit(search);
      });
  }
}
