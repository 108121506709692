import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    // TODO: fix this and use the language service
    // currently injecting the service wil cause a circular dependency,
    // the closes Info I found was this link https://stackoverflow.com/a/73889170
    // I think that - in our case - the translate service in depending on httpClient and causing the circular dependency
    const currentLanguage = localStorage.getItem("language") ?? 'en';
    const modifiedRequest = request.clone({
      headers: request.headers.set('Accept-Language', currentLanguage),
    });

    return next.handle(modifiedRequest);
  }
}
